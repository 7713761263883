import axios, { AxiosRequestConfig } from "axios";
import Config from "../config/Config";
import { CaptureBenefit } from "../models/CaptureBenefit";
import qs from "qs";


export enum CaptureBenefitFilterType {
    WatershedManagementGroup = 1,
    Watershed = 2,
    ProjectType = 3,
    Jurisdiction = 4
}

export enum CaptureBenefitFilterProjectType {
    Bmp = 1,
    LowFlowDiversion = 2,
    SpreadingGround = 3
}

export type CaptureBenefitAggregateTableViewModelRecord = {
    amount: number;
    count: number;
};

export class CaptureBenefitAggregateTableViewModel {
    lids: CaptureBenefitAggregateTableViewModelRecord = { amount: 0, count: 0 };
    greenStreets: CaptureBenefitAggregateTableViewModelRecord = { amount: 0, count: 0 };
    regionalProjects: CaptureBenefitAggregateTableViewModelRecord = { amount: 0, count: 0 };
    otherProjects: CaptureBenefitAggregateTableViewModelRecord = { amount: 0, count: 0 };
    spreadingGrounds: CaptureBenefitAggregateTableViewModelRecord = { amount: 0, count: 0 };
    lowFlowDiversions: CaptureBenefitAggregateTableViewModelRecord = { amount: 0, count: 0 };
};

export class CaptureBenefitService {
    protected config: AxiosRequestConfig;

    constructor(authToken?: string) {
        this.config = {
            headers: {
                "Authorization": "Bearer " + authToken
            },
        }
    }

    async getCaptureBenefits(ids: number[], startDate: Date, endDate: Date, type: CaptureBenefitFilterType | null): Promise<CaptureBenefit[]> {
        if ((type && ids.length === 0) || (!type && ids.length > 0)) {
            throw new Error("Must provide both type and id or neither to get capture benefits");
        }

        return await axios
            .get<CaptureBenefit[]>(Config.api_endpoint + 'CaptureBenefit',
                { ...this.config, params: { type: type, ids: ids, startDate: startDate, endDate: endDate }, paramsSerializer: (params) => qs.stringify(params) })
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting capture benefits" + e);
            });
    }

    async getRainfallByWeatherStationId(weatherStationId: number): Promise<CaptureBenefit[]> {
        return await axios
            .get<CaptureBenefit[]>(Config.api_endpoint + `RainEvent/WeatherStation/${weatherStationId}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting rainfall data" + e);
            });
    }

    async getCaptureBenefitAggregateTableViewModel(ids: number[], startDate: Date, endDate: Date, type: CaptureBenefitFilterType | null): Promise<CaptureBenefitAggregateTableViewModel> {
        if ((type && ids.length === 0) || (!type && ids.length > 0)) {
            throw new Error("Must provide both type and id or neither to get capture benefits");
        }

        return await axios
            .get<CaptureBenefitAggregateTableViewModel>(Config.api_endpoint + 'CaptureBenefit/SummaryTable',
                { ...this.config, params: { type: type, ids: ids, startDate: startDate, endDate: endDate, }, paramsSerializer: (params) => qs.stringify(params) })
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting capture benefits" + e);
            });
    }

    async getRainfallItsRaining(): Promise<boolean> {
        return await axios
            .get<boolean>(Config.api_endpoint + `RainEvent/ItsRaining`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting Its Raining rainfall data" + e);
            });
    }

    async getWeatherStationNameByBmpId(BmpId: number): Promise<string> {
        return await axios
            .get<string>(Config.api_endpoint + `RainEvent/WeatherStationNameByBmpId/${BmpId}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting WeatherStationNameById rainfall data" + e);
            });
    }

    async getWeatherStationNameById(id: number): Promise<string> {
        return await axios
            .get<string>(Config.api_endpoint + `RainEvent/WeatherStationNameById/${id}`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting WeatherStationNameById rainfall data" + e);
            });
    }

    async getWaterYear(): Promise<number> {
        return await axios.get<number>(Config.api_endpoint + `RainEvent/WhichWaterYear`, this.config)
            .then((result) => {
                return result.data;
            })
            .catch((e) => {
                throw new Error("Error getting WhichWateryear data" + e);
            });
    }
}
