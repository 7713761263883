import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import DatePicker from 'antd/es/date-picker';
import { Button, Dimmer, Loader, Icon, Dropdown, Message, MessageHeader, Popup } from "semantic-ui-react";
import dayjs, { Dayjs } from 'dayjs';
import { toast, ToastContainer } from "react-toastify";
import Select from 'react-select'

import { SpreadingGround } from '../../models/SpreadingGround';
import { CaptureBenefit } from "../../models/CaptureBenefit";
import LookupFactory from '../../lookups/LookupFactory';
import { SpreadingGroundService } from "../../services/SpreadingGroundService";
import { CaptureBenefitService } from "../../services/CaptureBenefitService";
import { CaptureBenefitFilterType, CaptureBenefitFilterProjectType, CaptureBenefitAggregateTableViewModel, } from "../../services/CaptureBenefitService";

import { DataBlock } from '../dataBlock/DataBlock';
import { CaptureDashboardMap } from '../map/CaptureDashboardMapComponent';
import LFDDetailsPanel from './LFDDetailsPanel';
import SGDetailsPanel from './SGDetailsPanel';
import CaptureSystemWideDetailsPanel from './CaptureSystemWideDetailsPanel';
import { CaptureDashboardGraph } from './CaptureDashboardGraph';

import { LowFlowDiversion } from '../../models/LowFlowDiversion';
import { LowFlowDiversionService } from '../../services/LowFlowDiversionService';
import { BmpGeneralViewModel, BmpService } from '../../services/BmpService';
import { BmpDetailsPanel } from './BmpDetailsPanel';
import { customFormat } from '../../core/NumberFormat';

import './CaptureDashboardContainer.scss';

type CaptureDashboardContainerProps = {
    lookups: LookupFactory;
}

type DateRange = {
    startDate: Date;
    endDate: Date;
};

export enum CaptureDashboardGraphType {
    Capture,
    Rainfall
}

export type CaptureDashboardConversionUnit = 'acft' | 'gallons' | 'swimmingPools' | 'roseBowls';
export type CaptureDashboardProjectTypes = 'systemWide' | 'completedWCM' | 'lfd' | 'spreadingGrounds';
export type CaptureDashboardDateRangeTypes = 'yearToDate' | 'recentEvent' | 'dateRange';

const dateRangeTypeDisplayValues = (dateRangeType: CaptureDashboardDateRangeTypes): string => {
    switch (dateRangeType) {
        case "yearToDate":
            return "Year to Date";
        case "recentEvent":
            return "Recent Event";
        case "dateRange":
            return "Date Range";
    }
};

const graphDropDownOptions: { key: number, text: string, value: CaptureDashboardGraphType }[] = [
    { key: CaptureDashboardGraphType.Capture, text: 'Capture', value: CaptureDashboardGraphType.Capture },
    { key: CaptureDashboardGraphType.Rainfall, text: 'Rainfall', value: CaptureDashboardGraphType.Rainfall },
];

const projectTypeFilterOptions = [
    { id: CaptureBenefitFilterType.WatershedManagementGroup, text: "Watershed Management Group", value: CaptureBenefitFilterType.WatershedManagementGroup },
    { id: CaptureBenefitFilterType.Watershed, text: "Watershed", value: CaptureBenefitFilterType.Watershed },
    { id: CaptureBenefitFilterType.ProjectType, text: "Project Type", value: CaptureBenefitFilterType.ProjectType },
    { id: CaptureBenefitFilterType.Jurisdiction, text: "Jurisdiction", value: CaptureBenefitFilterType.Jurisdiction }
];

const captureUnitOptions = {
    acft: "ac-ft",
    gallons: "Gallons",
    swimmingPools: "Swimming Pools",
    roseBowls: "Rose Bowls"
};

const lfdService = new LowFlowDiversionService();
const bmpService = new BmpService();
const spreadingGroundsService = new SpreadingGroundService();
const systemCaptureService = new CaptureBenefitService()
const currentDate = moment().toDate();
const waterYear: number = moment().isSameOrBefore(`${moment().year()}/10/01`) ? moment().subtract(1, 'years').year() : moment().year();
const toastMsgTime = 3000;

export const CaptureDashboardContainer: React.FC<CaptureDashboardContainerProps> = (props: CaptureDashboardContainerProps) => {
    // UI Options
    const [selectedProjectType, setSelectedProjectType] = useState<CaptureDashboardProjectTypes>("systemWide");
    const [showMoreDetailsOpen, setShowMoreDetailsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCaptureDetails, setIsLoadingCaptureDetails] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState<CaptureDashboardConversionUnit>("acft");
    const [displayDateRangePicker, setDisplayDateRangePicker] = useState<boolean>(false);
    const [selectedDateRangeType, setSelectedDateRangeType] = useState<CaptureDashboardDateRangeTypes>("yearToDate");
    const [graphType, setGraphType] = useState<CaptureDashboardGraphType>(CaptureDashboardGraphType.Capture);
    const [isMobile, setIsMobile] = useState(false);
    const [rainGaugeName, setRainGaugeName] = useState<string>("");
    const [waterYearDate, setWaterYearDate] = useState<Date>(new Date(waterYear, 9, 1)); // October 1st

    // individual project models
    const [bmpModel, setBmpModel] = useState<BmpGeneralViewModel | null>(null);
    const [lowFlowDiversion, setLowFlowDiversion] = useState<LowFlowDiversion | null>(null);
    const [spreadingGrounds, setSpreadingGrounds] = useState<SpreadingGround | null>(null);


    // date range
    const [dateRange, setDateRange] = useState<DateRange>({ startDate: waterYearDate, endDate: new Date() }); // default to water year to today

    // capture and rainfall data - unsorted and unfiltered by date
    const [captureDataAllSorted, setCaptureDataAllSorted] = useStateSorted([]);

    const [rainfallDataAllSorted, setRainfallDataAllSorted] = useStateSorted([]);

    const [aggregateTableData, setAggregateTableData] = useState<CaptureBenefitAggregateTableViewModel>(new CaptureBenefitAggregateTableViewModel());

    // filters they have selected
    const [selectedFilterType, setSelectedFilterType] = useState<CaptureBenefitFilterType | null>(null);
    const [selectedFilterSubOptions, setSelectedFilterSubOptions] = useState<number[]>([]);
    // saved filters they had selected when they hit the filter button
    const [appliedFilterType, setAppliedFilterType] = useState<CaptureBenefitFilterType | null>(null);
    const [appliedFilterSubOptions, setAppliedFilterSubOptions] = useState<number[]>([]);

    // Filter Sub Options
    const filterSubOptions:
        { key: string, text: string, options: { id: number, displayName: string, internalName: string, active: number, displayWeight: number }[] } = useMemo(() => {

            switch (selectedFilterType) {
                case CaptureBenefitFilterType.WatershedManagementGroup:
                    return {
                        key: "Watershed Management Group", text: "Watershed Management Group", options: props.lookups.lutWatershedManagementGroup
                    };

                case CaptureBenefitFilterType.Watershed:
                    return { key: "Watershed", text: "Watershed", options: props.lookups.lutWatershed };

                case CaptureBenefitFilterType.ProjectType:
                    return {
                        key: "Project Type", text: "Project Type", options: [
                            { id: CaptureBenefitFilterProjectType.Bmp, displayName: "Structural Control Measures", internalName: "", active: 1, displayWeight: 1 },
                            { id: CaptureBenefitFilterProjectType.LowFlowDiversion, displayName: "Low Flow Diversions", internalName: "", active: 1, displayWeight: 2 },
                            { id: CaptureBenefitFilterProjectType.SpreadingGround, displayName: "Spreading Grounds", internalName: "", active: 1, displayWeight: 3 }
                        ]
                    };
                case CaptureBenefitFilterType.Jurisdiction:
                    return {
                        key: "Jurisdiction", text: "Jurisdiction", options: props.lookups.lutJurisdiction
                    };

                default:
                    return { key: "", text: "", options: [] };
            }
        }, [props.lookups.lutJurisdiction, props.lookups.lutWatershed, props.lookups.lutWatershedManagementGroup, selectedFilterType]);

    const projectTypeTitles = useMemo(() => {
        switch (selectedFilterType) {
            case CaptureBenefitFilterType.WatershedManagementGroup:
                return `Watershed Management Group${selectedFilterSubOptions.length > 1 ? "s:" : ":"}`;
            case CaptureBenefitFilterType.Watershed:
                return `Watershed${selectedFilterSubOptions.length > 1 ? "s:" : ":"}`;
            case CaptureBenefitFilterType.ProjectType:
                return `Countywide:`;
            case CaptureBenefitFilterType.Jurisdiction:
                return `Jurisdiction${selectedFilterSubOptions.length > 1 ? "s:" : ":"}`;
            default:
                return "Countywide";
        }
    }, [selectedFilterSubOptions.length, selectedFilterType]); // it will update only when captureDataAllSorted changes and not when selectedFilterType changes

    const getSystemCapture = useCallback(async (ids: Array<number>, eventStart: Date, eventEnd: Date, filterType: CaptureBenefitFilterType | null) => {
        setIsLoading(true);
        try {
            const captureData = await systemCaptureService.getCaptureBenefits(ids, eventStart, eventEnd, filterType);
            const rainfallData = await systemCaptureService.getRainfallByWeatherStationId(1100);
            setCaptureDataAllSorted(captureData); // custom hook means it will be sorted
            setRainfallDataAllSorted(rainfallData); // custom hook means it will be sorted
            setIsLoading(false);

        } catch (error) {
            console.error('Error fetching countywide data:', error);
            toast.error('Failed to get countywide data', { containerId: "capture-dashboard-toast-container", autoClose: toastMsgTime });
            setIsLoading(false);
        }
    }, [setCaptureDataAllSorted, setRainfallDataAllSorted])

    const getSystemWideCaptureDetails = useCallback(async (ids: number[], eventStart: Date, eventEnd: Date, filterType: CaptureBenefitFilterType | null) => {
        setIsLoadingCaptureDetails(true);
        try {
            const captureData = await systemCaptureService.getCaptureBenefitAggregateTableViewModel(ids, eventStart, eventEnd, filterType);
            setAggregateTableData(captureData);
            getRainGaugeName(1100);
            setIsLoadingCaptureDetails(false);
        } catch (error) {
            console.error('Error fetching Countywide data:', error);
            setIsLoadingCaptureDetails(false);
            toast.error('Failed to get Countywide data ', { containerId: "capture-dashboard-toast-container", autoClose: toastMsgTime, });
        }
    }, []);

    const fetchWaterYearData = useCallback(async () => {
        let waterYearLocal = new Date(waterYear, 9, 1);
        const year = await systemCaptureService.getWaterYear();
        if (year !== waterYearLocal.getFullYear()) {
            waterYearLocal = new Date(year, waterYearLocal.getMonth(), waterYearLocal.getDate());
            setWaterYearDate(waterYearLocal);
            setDateRange({ startDate: waterYearLocal, endDate: currentDate });
        }
        getSystemCapture([], waterYearLocal, currentDate, null);
        getSystemWideCaptureDetails([], waterYearLocal, currentDate, null);
        getRainGaugeName(1100);
    }, [getSystemCapture, getSystemWideCaptureDetails]);

    // remove side-bar from the main-wrapper-container so the page can be full width 
    useEffect(() => {
        fetchWaterYearData();

        setIsMobile(isMobileDevice());
        const parentElement = document.querySelector('.main-wrapper-container') as HTMLElement;
        let originalDisplay = '';

        if (parentElement) {
            originalDisplay = window.getComputedStyle(parentElement).display;
            parentElement.style.display = 'block';
        }
        // Component did Unmount Cleanup
        return () => {
            if (parentElement) {
                parentElement.style.display = originalDisplay;
            }
        };
    }, [fetchWaterYearData]);

    // Data for any type of Project or System-Wide

    const getBmpRainGaugeName = async (id: number) => {
        try {
            const rainGaugeName = await systemCaptureService.getWeatherStationNameByBmpId(id);
            setRainGaugeName(rainGaugeName);

        } catch (error) {
            console.error('Error fetching BMP rain gauge data:', error);
        }
    };

    const getRainGaugeName = async (id: number) => {
        try {
            const rainGaugeName = await systemCaptureService.getWeatherStationNameById(id);
            setRainGaugeName(rainGaugeName);

        } catch (error) {
            console.error('Error fetching rain gauge data:', error);
        }
    };

    const captureDataInDateRange = useMemo(() => {
        if (isLoading) return [];

        const captureInDateRange = filterByDateRange(captureDataAllSorted, dateRange)
            .map((record) => ({ ...record, amount: convertAcreFeetToUnit(record.amount, selectedUnit) }));

        return captureInDateRange;
    }, [captureDataAllSorted, dateRange, isLoading, selectedUnit]);

    const rainfallDataInDateRange = useMemo(() => {
        if (isLoading) return [];

        return filterByDateRange(rainfallDataAllSorted, dateRange);

    }, [rainfallDataAllSorted, dateRange, isLoading]);

    const captureSum: number | null = useMemo(() => {
        if (isLoading) return null;

        return captureDataInDateRange.reduce((acc, val) => acc + val.amount, 0);
    }, [captureDataInDateRange, isLoading]);

    const rainfallSum: number | null = useMemo(() => {
        if (isLoading) return null;

        return rainfallDataInDateRange.reduce((acc, val) => acc + val.amount, 0);
    }, [isLoading, rainfallDataInDateRange]);

    const recentRainfall: CaptureBenefit | null = useMemo(() => {
        if (isLoading) return null;
        // Most recent rainfall is regardless of dateRange.
        return rainfallDataAllSorted.at(-1);
    }, [isLoading, rainfallDataAllSorted]) ?? null;

    const recentCapture: CaptureBenefit | null = useMemo(() => {
        if (isLoading && !recentRainfall) return null;

        // Most recent capture is regardless of dateRange and is not the most recent dry event, only the most recent wet event!
        return captureDataAllSorted.find(x => x.eventStart === recentRainfall?.eventStart && x.eventEnd === recentRainfall.eventEnd) ?? {
            // handle lfd recent dry event
            ...captureDataInDateRange.at(-1), eventStart: captureDataInDateRange.at(-1)?.eventStart, eventEnd: currentDate
        };

    }, [captureDataAllSorted, isLoading, recentRainfall, captureDataInDateRange]);

    const isLFD = useMemo(() => {
        return selectedProjectType === "lfd" ||
            // When at Countywide LFD is selected as single filter parameter
            (selectedFilterType === CaptureBenefitFilterType.ProjectType &&
                selectedFilterSubOptions.length === 1 &&
                selectedFilterSubOptions[0] === CaptureBenefitFilterProjectType.LowFlowDiversion
            );
    }, [selectedProjectType, selectedFilterType, selectedFilterSubOptions]);

    const scrollToTop = (): void => {
        const container = document.querySelector('.capture-dashboard');

        container.scrollTo({ top: 200, behavior: 'smooth' });
    };

    // Individual Project Clicks on Map

    const onBmpMapClick = useCallback(async (bmpId: number) => {
        setIsLoading(true);
        try {
            const bmp = await bmpService.getViewModelById(bmpId);
            const capture = await bmpService.getBenefitById(bmpId);
            const rainfall = await bmpService.getRainfallByBmpId(bmpId);

            setSelectedProjectType("completedWCM");
            setBmpModel(bmp);
            setCaptureDataAllSorted(capture);
            setRainfallDataAllSorted(rainfall);
            setIsLoading(false);
            getBmpRainGaugeName(bmpId);
            // toast.info(<p> <b>{bmp?.name}</b> data loaded to dashboard</p>, { containerId: "capture-dashboard-toast-container", autoClose: toastMsgTime, });
            scrollToTop();

        } catch (error) {
            console.error('Error fetching BMP data:', error);
            toast.error('Failed to get BMP data ', { containerId: "capture-dashboard-toast-container", autoClose: toastMsgTime, });
            setIsLoading(false);
        }
    }, [setCaptureDataAllSorted, setRainfallDataAllSorted]);

    const onLowFlowDiversionMapClick = useCallback(async (lfdId: number) => {
        setIsLoading(true);
        try {
            const lowFlowDiversion = await lfdService.getById(lfdId);
            const capture = await lfdService.getBenefitById(lfdId);

            setLowFlowDiversion(lowFlowDiversion);
            setCaptureDataAllSorted(capture);
            // no rainfall for LFDs since they are dry weather only
            setSelectedProjectType("lfd");
            setGraphType(CaptureDashboardGraphType.Capture); // LFDs don't show rainfall
            // toast.info(<p> <b>{lowFlowDiversion?.name}</b> data loaded to dashboard</p>, { containerId: "capture-dashboard-toast-container", autoClose: toastMsgTime, });
            setIsLoading(false);
            scrollToTop();

        } catch (error) {
            console.error('Error fetching LFD data:', error);
            toast.error('Failed to get LFD data ', { containerId: "capture-dashboard-toast-container", autoClose: toastMsgTime, });
            setIsLoading(false);
        }
    }, [setCaptureDataAllSorted]);


    const onSpreadingGroundsMapClick = useCallback(async (spreadingGroundsId: number) => {
        setIsLoading(true);
        try {
            const spreadingGround = await spreadingGroundsService.getById(spreadingGroundsId);
            const capture = await spreadingGroundsService.getBenefitById(spreadingGroundsId);
            const rainfall = await spreadingGroundsService.getRainFallById(spreadingGroundsId);

            setSelectedProjectType("spreadingGrounds");
            setSpreadingGrounds(spreadingGround);
            setCaptureDataAllSorted(capture);
            setRainfallDataAllSorted(rainfall);
            getRainGaugeName(1103);
            // toast.info(<p> <b>{spreadingGround?.name}</b> data loaded to dashboard</p>, { containerId: "capture-dashboard-toast-container", autoClose: toastMsgTime, });
            setIsLoading(false);
            scrollToTop();

        } catch (error) {
            console.error('Error fetching spreading grounds data:', error);
            toast.error('Failed to get Spreading Grounds data ', { containerId: "capture-dashboard-toast-container", autoClose: toastMsgTime, });
            setIsLoading(false);
        }
    }, [setCaptureDataAllSorted, setRainfallDataAllSorted]);

    // Click Handlers

    const handleSetBackToSystemWideData = () => {
        setSelectedProjectType("systemWide");
        setBmpModel(null);
        setLowFlowDiversion(null);
        setSpreadingGrounds(null);
        getSystemCapture(appliedFilterSubOptions, waterYearDate, currentDate, appliedFilterType);
        getSystemWideCaptureDetails(appliedFilterSubOptions, waterYearDate, currentDate, appliedFilterType);
    };


    const handleFilterOptionsChange = (idList: number[]) => {
        setSelectedFilterSubOptions(idList);
    };

    const handleFilterButton = async () => {
        setAppliedFilterType(selectedFilterType);
        setAppliedFilterSubOptions(selectedFilterSubOptions);
        // consider remove and change to useEffect on change of applied filter
        getSystemCapture(selectedFilterSubOptions, dateRange.startDate, dateRange.endDate, selectedFilterType);
        getSystemWideCaptureDetails(selectedFilterSubOptions, dateRange.startDate, dateRange.endDate, selectedFilterType);
    };

    const handleResetFilterButton = () => {
        setSelectedFilterType(null);
        setSelectedFilterSubOptions([]);
        setAppliedFilterType(null);
        setAppliedFilterSubOptions([]);
        getSystemCapture([], dateRange.startDate, dateRange.endDate, null);
        getSystemWideCaptureDetails([], dateRange.startDate, dateRange.endDate, null);
    };


    // Date Change Handlers
    const handleYearToDateClick = (): void => {
        setDateRange({ startDate: waterYearDate, endDate: currentDate }); // sets back to default if clicked from RecentRainEvent
        setSelectedDateRangeType("yearToDate");
        setDisplayDateRangePicker(false);
        getSystemWideCaptureDetails(selectedFilterSubOptions, waterYearDate, currentDate, appliedFilterType);
    };

    const handleRecentEventClick = (): void => {
        setSelectedDateRangeType("recentEvent");
        setDisplayDateRangePicker(false);
        getSystemWideCaptureDetails(selectedFilterSubOptions, recentCapture?.eventStart, recentCapture?.eventEnd, selectedFilterType);
    };

    const handleDateRangeClick = (): void => {
        // open the date range picker
        if (selectedDateRangeType === "dateRange") {
            return;
        }
        setSelectedDateRangeType("dateRange");
        setDateRange({ startDate: waterYearDate, endDate: currentDate });
        setDisplayDateRangePicker(true);
    };

    const handleDateRange = (startDate: Dayjs | null, endDate: Dayjs | null): void => {
        if (startDate && endDate) {
            setDateRange({ startDate: startDate.toDate(), endDate: endDate.toDate() });
            if (selectedProjectType === "systemWide") {
                // only getting system wide data here because it only returns values for the date range, but individual projects return all data
                getSystemCapture(appliedFilterSubOptions, startDate.toDate(), endDate.toDate(), appliedFilterType);
            }
        }
    };



    return (
        <div className='capture-dashboard'>
            <ToastContainer enableMultiContainer containerId={"capture-dashboard-toast-container"} position="top-center" hideProgressBar={true} autoClose={false} />
            <div className='capture-dashboard__page-header gradient'>
                <h1 className='capture-dashboard__page-header--title'>
                    Los Angeles County <br /><span>Stormwater Capture Dashboard</span>
                    <br></br>
                </h1>
                <p className='capture-dashboard__page-header--title-sub-title'>Real-time information on rainfall and stormwater capture in our community. <br></br>Track our progress on capturing stormwater to increase our local water supply, improve water quality, and protect public health!</p>
            </div>
            <div className='capture-dashboard__container'>
                <div className='capture-dashboard__widget-sticky-container'>
                    <div className={`capture-dashboard__title-container capture-dashboard__widget-container`}>
                        <h1 className='capture-dashboard__title-container-title'>
                            {selectedProjectType === "systemWide" && projectTypeTitles}
                            {selectedProjectType === "completedWCM" && bmpModel?.name}
                            {selectedProjectType === "lfd" && lowFlowDiversion?.name}
                            {selectedProjectType === "spreadingGrounds" && spreadingGrounds?.name}
                        </h1>
                        <div className="capture-dashboard__title-container-type-section" >
                            {selectedProjectType === "completedWCM" && <p style={{ marginRight: "2em", fontSize: "16px" }}>
                                <span style={{ color: "#5c5c5c", fontSize: "13px" }}>Project Type</span>
                                <br></br>
                                <b>{bmpModel && props.lookups.getBmpTypeById(bmpModel?.bmpTypeId)}</b>
                            </p>}
                            {selectedProjectType === "systemWide" && <>
                                <div className="capture-dashboard__option-menu" >
                                    <label className="capture-dashboard__input-label">Filter by</label>
                                    <Dropdown
                                        className='capture-dashboard__graph-dropdown'
                                        style={{
                                            minWidth: "18em",
                                            margin: "0.51em 1em 0 0em"
                                        }}
                                        placeholder='Select Option'
                                        fluid
                                        selection
                                        options={projectTypeFilterOptions}
                                        dropdownItems={projectTypeFilterOptions.map(x => x.text)}
                                        value={selectedFilterType ?? null}
                                        onChange={(_, data) => {
                                            setSelectedFilterSubOptions([]);
                                            const nativeEvent = _.nativeEvent as PointerEvent;
                                            nativeEvent?.pointerType && setSelectedFilterType(data.value as CaptureBenefitFilterType);
                                        }}
                                    />
                                </div>
                                <div className="capture-dashboard__option-menu" style={{ visibility: selectedFilterType ? "visible" : "hidden" }}>
                                    <label className="capture-dashboard__input-label">Options</label>
                                    <Select
                                        isClearable={false}
                                        isMulti
                                        placeholder='Select Sub-Option'
                                        options={filterSubOptions.options.map(x => ({ value: x.id, label: x.displayName }))}
                                        onChange={(_Values) => {
                                            handleFilterOptionsChange(_Values.map(x => x.value))
                                        }}
                                        value={filterSubOptions.options.filter(x => selectedFilterSubOptions.includes(x.id)).map(x => ({ value: x.id, label: x.displayName }))}
                                        backspaceRemovesValue={true}
                                        escapeClearsValue={true}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                marginTop: "0.51em",
                                                marginRight: "2em",
                                                width: "19em"
                                            }),
                                            multiValueLabel: (styles) => ({
                                                ...styles,
                                                color: "primary",
                                            }),
                                            multiValue: (styles) => {
                                                return {
                                                    ...styles,
                                                    backgroundColor: "#2684FF",
                                                    color: "white",
                                                    borderRadius: "5px",
                                                };
                                            },
                                            multiValueRemove: (styles) => ({
                                                ...styles,
                                                color: "white",
                                                ':hover': {
                                                    backgroundColor: "#0b4b9e",
                                                    color: 'white',
                                                },
                                            }),
                                        }}
                                    />
                                </div>
                                <div>

                                    <Button
                                        style={{ visibility: selectedFilterSubOptions.length > 0 ? "visible" : "hidden" }}
                                        className={`data-block-container__button--active capture-dashboard__filter-btn `}
                                        onClick={() => handleFilterButton()}
                                    >
                                        Filter
                                    </Button>
                                    <Button
                                        style={{ visibility: selectedFilterType ? "visible" : "hidden" }}
                                        className={`data-block-container__button capture-dashboard__filter-btn `}
                                        onClick={() => handleResetFilterButton()}
                                    >
                                        Reset
                                    </Button>
                                </div>

                            </>}
                            {selectedProjectType !== "systemWide" && <p style={{ marginRight: "3em", fontSize: "16px" }}>
                                <span style={{ color: "#5c5c5c", fontSize: "13px" }}>Project Class</span>
                                <br></br>
                                {selectedProjectType === "completedWCM" && <b>{bmpModel && props.lookups.getBmpClassById(bmpModel?.bmpClassId)}</b>}
                                {selectedProjectType === "lfd" && <b>Low Flow Diversion</b>}
                                {selectedProjectType === "spreadingGrounds" && <b>Spreading Grounds</b>}
                            </p>}
                            <div style={{ display: "flex" }}>
                                <Button
                                    className="capture-dashboard__button capture-dashboard__button--circular"
                                    style={{ bottom: `${(selectedProjectType === "lfd" || selectedProjectType === "spreadingGrounds") ? "0.5em" : "-0.5em"}` }}
                                    onClick={() => setShowMoreDetailsOpen((isOpen) => !isOpen)}
                                >
                                    {showMoreDetailsOpen ?
                                        <Icon name="minus" size='large' style={{ color: 'white' }} /> : <Icon name="plus" size='large' style={{ color: 'white' }} />
                                    }
                                </Button>
                                {selectedProjectType !== "systemWide" && <Button
                                    className={`data-block-container__button`}
                                    style={{ marginLeft: "0.31em", height: "3.3em", width: "17em" }}
                                    onClick={handleSetBackToSystemWideData}
                                >
                                    Back to Countywide Data
                                </Button>}
                            </div>
                        </div>
                    </div>
                    {showMoreDetailsOpen &&
                        <>
                            {selectedProjectType === "completedWCM" && bmpModel &&
                                <BmpDetailsPanel
                                    bmpModel={bmpModel}
                                    lookups={props.lookups}
                                />
                            }
                            {selectedProjectType === "lfd" &&
                                <LFDDetailsPanel
                                    lfdName={lowFlowDiversion?.name ?? ""}
                                    jurisdiction={props.lookups.getJurisdictionById(1089)}
                                    areaManaged={lowFlowDiversion?.drainageAreaAc.toFixed(2)}
                                    dailyFlow={lowFlowDiversion?.dailyFlowGal ?? 0}
                                    maxFlowGpm={lowFlowDiversion?.maxFlowGpm.toFixed(2)}
                                    is24Hour={lowFlowDiversion?.is24Hour}
                                    operatingHours={{ startHour: lowFlowDiversion?.startHour, endHour: lowFlowDiversion?.endHour }}
                                    completionDate={moment(lowFlowDiversion?.completionDate).format("MM-DD-YYYY")}
                                />
                            }
                            {selectedProjectType === "spreadingGrounds" &&
                                <SGDetailsPanel
                                    sgData={spreadingGrounds}
                                />
                            }
                            {selectedProjectType === "systemWide" &&
                                <CaptureSystemWideDetailsPanel
                                    isLoading={isLoadingCaptureDetails}
                                    tableData={aggregateTableData}
                                />
                            }
                        </>
                    }

                </div>





                {/*
                ////////////////////////////////
                    Capture and Rainfall Totals
                ////////////////////////////////
                 */}
                <Dimmer inverted active={isLoading}>
                    <Loader active={isLoading} />
                </Dimmer>
                <div>
                    <div className="data-block-container ">
                        <div className="data-block-container__block-box">
                            <DataBlock
                                // key={yearToDateData.totalCapture} // or another unique value that changes when the data changes
                                title={selectedDateRangeType === "recentEvent" ? customFormat(convertAcreFeetToUnit(recentCapture?.amount, selectedUnit) ?? 0, true) : customFormat(captureSum ?? 0, true)}
                                description={"CAPTURE"}
                                infoType={captureUnitOptions[selectedUnit]}
                            />
                            <DataBlock
                                title={selectedDateRangeType === "recentEvent" ? customFormat(convertAcreFeetToUnit(recentRainfall?.amount, selectedUnit) ?? 0, true) : customFormat(rainfallSum ?? 0, true)}
                                description={"RAINFALL"}
                                infoType="in"
                            />
                        </div>

                        <div id="datePiker" style={{ display: "flex", margin: "0 auto 1em" }}>
                            <Button className={`data-block-container__button${selectedDateRangeType === "yearToDate" ? '--active' : ''}`} style={{ marginRight: ".3em" }} onClick={handleYearToDateClick}>Year to Date</Button>
                            <Button className={`data-block-container__button${selectedDateRangeType === "recentEvent" ? '--active' : ''}`} onClick={handleRecentEventClick}>Recent Rain Event</Button>
                            <Button className={`data-block-container__button${selectedDateRangeType === "dateRange" ? '--active' : ''}`} onClick={handleDateRangeClick}>Custom Dates</Button>
                        </div>
                        {(displayDateRangePicker === true && selectedDateRangeType === "dateRange") &&
                            <div className={`capture-dashboard__date-range-picker-container ${selectedDateRangeType === "dateRange" ? "capture-dashboard__date-range-picker-container--active" : ""}`} style={{ margin: "2em 0 0", textAlign: "center" }}>
                                <DatePicker.RangePicker
                                    id="captureDashboardDateRangePicker"
                                    size="large"
                                    format="MM-DD-YYYY"
                                    className={"capture-dashboard__date-range-picker"}
                                    style={{ boxShadow: "12px 6px 20px rgba(0, 0, 0, 0.19), 2px 5px 9px 0px rgba(0, 0, 0, 0.23) !important" }}
                                    defaultValue={[dayjs(waterYearDate), dayjs()]} // default to water year to today
                                    onChange={(dateValues) => handleDateRange(dateValues?.[0] ?? null, dateValues?.[1] ?? null)}
                                    disabledDate={(current) => current && current.isAfter(dayjs())} // disable future dates

                                />
                            </div>}
                    </div>
                </div>

                {/*
                //////////////////////////////
                    Data Graph Widget
                ////////////////////////////
                */}
                <div className="capture-dashboard__widget-container">
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <h2 style={{ fontSize: "1.9em", marginBottom: "1.5em" }}>Capture and Rainfall Over Time</h2>
                        <Message
                            className="capture-dashboard__date-banner-container"
                            color='blue'
                        >
                            <MessageHeader>
                                <p style={{ fontSize: 14, fontWeight: 100, marginTop: "-0.21em" }}>
                                    {dateRangeTypeDisplayValues(selectedDateRangeType)}:
                                    <span style={{ fontSize: 16, fontWeight: 800 }}>
                                        {selectedDateRangeType === "yearToDate" &&
                                            ` ${moment(waterYearDate).format("MM/DD/YYYY")} to ${moment().format("MM/DD/YYYY")}`
                                        }
                                        {selectedDateRangeType === "recentEvent" && recentCapture?.eventStart &&
                                            ` ${moment(recentCapture.eventStart).format("MM/DD/YYYY")} to ${moment(recentCapture.eventEnd).format("MM/DD/YYYY")}`
                                        }
                                        {selectedDateRangeType === "dateRange" && dateRange.startDate && dateRange.endDate &&
                                            ` ${moment(dateRange.startDate).format("MM/DD/YYYY")} to ${moment(dateRange.endDate).format("MM/DD/YYYY")}`
                                        }
                                    </span>
                                </p>
                            </MessageHeader>
                        </Message>
                    </div>

                    <h4 style={{ margin: "1em 0px 2em", fontStyle: "italic" }}>The chart below shows capture and rainfall amounts during this water year.  The captured water supports a healthy environment and augments local supplies.</h4>
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        {(selectedProjectType === "systemWide" || selectedProjectType === "completedWCM" || selectedProjectType === "spreadingGrounds") &&
                            <div style={{ marginBottom: "1.5em" }}>
                                <label className="capture-dashboard__input-label">Display Type</label>
                                <div style={{ display: "flex", width: "40em", alignItems: "baseline" }}>
                                    <Dropdown
                                        className="capture-dashboard__graph-dropdown"
                                        style={{ minWidth: "14em", marginTop: "0.51em" }}
                                        placeholder='Select Option'
                                        fluid
                                        selection
                                        options={graphDropDownOptions}
                                        value={graphType}
                                        onChange={(_, data) => setGraphType(data.value as CaptureDashboardGraphType)}
                                    />
                                    {graphType === CaptureDashboardGraphType.Rainfall && <p
                                        style={{
                                            background: "white",
                                            borderRadius: "6px",
                                            padding: "0.51em 9px",
                                            border: "solid 1px #e8e8e8"
                                        }}
                                    >
                                        Gauge: <b>{rainGaugeName}</b>
                                    </p>}
                                </div>
                            </div>
                        }
                        {graphType === CaptureDashboardGraphType.Capture && <div>
                            <label className="capture-dashboard__input-label">Capture Units</label>
                            <div style={{ marginTop: "0.51em" }}>
                                <Button
                                    className={`data-block-container__button capture-dashboard__button-cheap  capture-dashboard__button--sm ${selectedUnit === "acft" && "data-block-container__button--active"}`}
                                    style={{ marginRight: "0.2em" }}
                                    onClick={() => setSelectedUnit("acft")}
                                >
                                    Acre-feet
                                </Button>
                                <Button
                                    className={`data-block-container__button capture-dashboard__button-cheap capture-dashboard__button--sm ${selectedUnit === "gallons" && "data-block-container__button--active"}`}
                                    onClick={() => setSelectedUnit("gallons")}
                                >
                                    Gallons
                                </Button>


                                <Popup
                                    key={"swimmingPoolPopup"}
                                    header={"Swimming Pools"}
                                    content={
                                        <p>
                                            <br></br> This assumption is based on the size of a competitive swimming pool used in the Olympics, which is 50 meters in length, 25 meters in width, and 2 meters deep. <br></br> This results in a swimming pool that is approximately just over 2 acre-feet.
                                        </p>
                                    }
                                    trigger={
                                        <Button
                                            className={`data-block-container__button capture-dashboard__button-cheap capture-dashboard__button--sm ${selectedUnit === "swimmingPools" && "data-block-container__button--active"}`}
                                            onClick={() => setSelectedUnit("swimmingPools")}
                                        >
                                            Swimming pools
                                        </Button>
                                    }
                                ></Popup>
                                <Popup
                                    key={"roseBowlPopup"}
                                    header={"Rose Bowls"}
                                    content={
                                        <p>
                                            <br></br>  Unlike a swimming pool, the Rose Bowl has a more complex geometry, which makes it more challenging to develop a precise volume. <br></br>Published estimates can vary widely depending on whether just the inner field or the entire event site is used.<br></br> This graph uses simplified assumptions that the Rose Bowl is approximately 259 acre-feet.
                                        </p>
                                    }
                                    trigger={
                                        <Button
                                            className={`data-block-container__button capture-dashboard__button-cheap capture-dashboard__button--sm ${selectedUnit === "roseBowls" && "data-block-container__button--active"}`}
                                            onClick={() => setSelectedUnit("roseBowls")}
                                        >
                                            Rose Bowls
                                        </Button>
                                    }
                                ></Popup>
                            </div>
                        </div>}
                    </div>
                    {(selectedProjectType === "spreadingGrounds" || (selectedProjectType === "systemWide" && selectedFilterSubOptions.length === 0) || (selectedProjectType === "systemWide" && selectedFilterSubOptions[0] === 3)) && <div className="capture-dashboard__sg-label-container">
                        <div className="capture-dashboard__sg-icon-container">
                            <i className={"capture-dashboard__sg-icon"}></i>
                            <p>Spreading ground capture estimates are preliminary and uploaded monthly (not real-time).
                            </p>
                        </div>
                    </div>}
                    <div style={{ display: "flex", margin: "0 auto" }}></div>
                    <CaptureDashboardGraph
                        graphData={graphType === CaptureDashboardGraphType.Capture ? captureDataInDateRange : rainfallDataInDateRange}
                        dataType={graphType}
                        unitType={selectedUnit}
                        dateRangeType={selectedDateRangeType}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        recentRainfall={recentRainfall}
                        isLfd={isLFD}
                    />
                </div>

                <div className="capture-dashboard__widget-container" style={{ marginTop: "5em", height: `${isMobile ? "645px" : "900px"}` }} >
                    <h2 style={{ marginBottom: "-0.52em" }}>Explore Capture Facilities</h2>
                    <h4 style={{ marginBottom: "1.5em", fontStyle: "italic" }}>Please use the map below to explore additional details of capture, for example by exploring specific jurisdictions or individual facilities.</h4>
                    <div className="map-wrapper">
                        <CaptureDashboardMap
                            onBmpClick={onBmpMapClick}
                            onLfdClick={onLowFlowDiversionMapClick}
                            onSgClick={onSpreadingGroundsMapClick}
                            selectedFilterType={appliedFilterType}
                            selectedFilterIds={appliedFilterSubOptions}
                            filterStartDate={dateRange.startDate}
                            filterEndDate={dateRange.endDate}
                            lookups={props.lookups}
                            isMobile={isMobile}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};


function filterByDateRange(projectRecords: CaptureBenefit[], dateRange: DateRange): CaptureBenefit[] {
    // set to beginning of the day
    const startRangeDate = moment(dateRange.startDate).startOf('day');
    const endRangeDate = moment(dateRange.endDate).endOf('day');

    // filters records who's start date is after the dateRange Start
    // AND the start is before the dateRange End

    return projectRecords.filter((record) => {
        const recordDate = moment(record.eventStart);
        return recordDate.isAfter(startRangeDate, 'hour') && recordDate.isBefore(endRangeDate, 'hour');
    });
};

function sortByDateDescending(recordsArray: CaptureBenefit[]): CaptureBenefit[] {
    return [...recordsArray].sort((a, b) => {
        const dateA = moment(a.eventStart);
        const dateB = moment(b.eventStart);
        return dateA.diff(dateB);
    });
};

//Ac-ft to Gallons
function acreFeetToGallons(acreFeet: number): number {
    return acreFeet * 325851.4; // 1 acre-foot = 325,851.4 gallons
}


// Ac-ft to Swimming Pools
function acreFeetToSwimmingPools(acreFeet: number): number {
    const cubicFeetPerAcreFoot: number = 43560;
    const cubicFeetPerSwimmingPool: number = 88286.7; // Olympic-sized pool volume in cubic feet

    return (acreFeet * cubicFeetPerAcreFoot) / cubicFeetPerSwimmingPool;
}

// Ac-ft to Rose Bowls
function acreFeetToRoseBowls(acreFeet: number): number {
    const cubicFeetPerAcreFoot: number = 43560;
    const roseBowlVolumeInCubicFeet: number = 11279409.6;

    return ((acreFeet * cubicFeetPerAcreFoot) / roseBowlVolumeInCubicFeet);
}

function convertAcreFeetToUnit(acft: number, unit: CaptureDashboardConversionUnit): number {

    switch (unit) {
        case 'gallons':
            return acreFeetToGallons(acft);

        case 'swimmingPools':
            return (acreFeetToSwimmingPools(acft));

        case 'roseBowls':
            return acreFeetToRoseBowls(acft);

        default:
            return acft;
    }
}

function isMobileDevice() {
    const mobileDeviceWidth = 480;
    const isMobileWidth = window.innerWidth <= mobileDeviceWidth;

    return isMobileWidth;
}

/* Custom Hook to sort the state by date descending
*/
const useStateSorted: (initialState: CaptureBenefit[]) => [CaptureBenefit[], (newValue: CaptureBenefit[]) => void] = (initialValue: CaptureBenefit[]) => {
    const [value, setValue] = useState<CaptureBenefit[]>(initialValue);

    const setValueAndSort = useCallback((newValue: CaptureBenefit[]) => {
        setValue(sortByDateDescending(newValue));
    }, []);

    return [value, setValueAndSort];
};